<template>
    <!-- <section class="menu-burger"> -->
        <div :class="isActive ? 'active' : ''" @click="changeState" class="burger-container">
            <div class="burger"></div>
        </div>
        <div :class="isActive ? 'active' : ''" class="menu-container">
            <nav class="menu mobile-menu">
                <div>
                    <img class="logo" src="../../assets/img/logo.png" alt="">
                    <ul>
                        <li>
                            <router-link @click="changeState(400)" to="/admin/dashboard"><img src="../../assets/img/dashboard.svg" alt=""> Tableau de bord</router-link>
                        </li>
                        <li >
                            <router-link :class="checkUrl == true ? 'research-active' : ''" class="flex-link" to="#">
                                <span>
                                    <img src="../../assets/img/search.svg" alt=""> 
                                    Recherche 
                                    <img class="arrow" src="../../assets/img/down-arrow.svg" alt="">
                                </span>
                                <ul>
                                    <li><router-link @click="showPopUpActivite" to="#">Activités rituelles</router-link></li>
                                    <li><router-link @click="showPopUpJeu" to="#">Jeux sérieux</router-link></li>
                                    <li><router-link @click="changeState(400)" to="/admin/recherche/evaluation">Évaluations</router-link></li>
                                </ul>
                            </router-link>
                            
                        </li>
                        <li>
                            <router-link @click="changeState(400)" to="/admin/classe"><img src="../../assets/img/classes.svg" alt=""> Classes</router-link>
                        </li>
                        <li>
                            <router-link @click="changeState(400)" to="/admin/ressource"><img src="../../assets/img/ressources.svg" alt=""> Ressources</router-link>
                        </li>
                        <li>
                            <router-link @click="changeState(400)"  to="/admin/agenda"><img src="../../assets/img/calendar.svg" alt=""> Agenda</router-link>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li>
                            <router-link @click="changeState(400)" to="/admin/parametre"><img src="../../assets/img/setting.svg" alt=""> Paramètres</router-link>
                        </li>
                        <li>
                            <router-link @click="changeState(400)" to="/auth/"><img src="../../assets/img/deco.svg" alt=""> Déconnexion</router-link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    <!-- </section> -->
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        changeState(e){
            setTimeout(()=>{
                this.isActive = !this.isActive
            },e)
        }
    },
}
</script>

<style>

</style>