<template>
    <nav class="menu">
        <div>
            <img class="logo" src="../../assets/img/logo.png" alt="">
            <ul>
                <li>
                    <router-link :class="isActive.includes('/dashboard') ? 'active' : '' " to="/admin/dashboard"><img src="../../assets/img/dashboard.svg" alt=""> Tableau de bord</router-link>
                </li>
                <li >
                    <router-link :class="checkUrl == true ? 'research-active' : ''" class="flex-link" to="#">
                        <span>
                            <img src="../../assets/img/search.svg" alt=""> 
                            Recherche 
                            <img class="arrow" src="../../assets/img/down-arrow.svg" alt="">
                        </span>
                        <ul>
                            <li><router-link :class="isActive.includes('/clef-en-main') || isActive.includes('/questions-individuelles') ? 'active' : '' " @click="showPopUpActivite" to="#">Activités rituelles</router-link></li>
                            <li><router-link :class="isActive.includes('/recherche-eleve') || isActive.includes('/recherche-classe') ? 'active' : '' "  @click="showPopUpJeu" to="#">Jeux sérieux</router-link></li>
                            <li><router-link :class="isActive.includes('/evaluation') ? 'active' : '' " to="/admin/recherche/evaluation">Évaluations</router-link></li>
                        </ul>
                    </router-link>
                    
                </li>
                <li>
                    <router-link :class="isActive.includes('/classe') ? 'active' : '' " to="/admin/classe"><img src="../../assets/img/classes.svg" alt=""> Classes</router-link>
                </li>
                <li>
                    <router-link :class="isActive.includes('/ressource') ? 'active' : '' " to="/admin/ressource"><img src="../../assets/img/ressources.svg" alt=""> Ressources</router-link>
                </li>
                <li>
                    <router-link :class="isActive.includes('/agenda') ? 'active' : '' " to="/admin/agenda"><img src="../../assets/img/calendar.svg" alt=""> Agenda</router-link>
                </li>
            </ul>
        </div>
        <div>
            <ul>
                <li>
                    <router-link :class="isActive.includes('/parametre') ? 'active' : '' " to="/admin/parametre"><img src="../../assets/img/setting.svg" alt=""> Paramètres</router-link>
                </li>
                <li>
                    <router-link to="/auth/"><img src="../../assets/img/deco.svg" alt=""> Déconnexion</router-link>
                </li>
            </ul>
        </div>
    </nav>
    <pop-up-activite @closed="timeToCloseActivite" v-if="isVisibleActivite"/>
    <pop-up-jeu @closed="timeToCloseJeu" v-if="isVisibleJeu"/>
</template>

<script>

import PopUpActivite from '../PopUp/PopUpActivite.vue'
import PopUpJeu from '../PopUp/PopUpJeu.vue'

export default {
    components: {
       PopUpActivite,
       PopUpJeu,
    },
    data() {
        return {
            isVisibleActivite: false,
            isVisibleJeu: false,
        }
    },
    computed: {
        isActive(){
            
            return this.$route.path
			
        },
        checkUrl(){
			if (this.$route.path.includes('/recherche/')) { 
                return true; 
            }
            else{
                return false
            }
        }
    },

    methods: {
        showPopUpActivite(){
            this.isVisibleActivite = true;
        },
        timeToCloseActivite(){
            this.isVisibleActivite = false;
        },
        showPopUpJeu(){
            this.isVisibleJeu = true;
        },
        timeToCloseJeu(){
            this.isVisibleJeu = false;
        }
        
    },
    
}
</script>

<style>

</style>


