<template>
    <section :class="smooth ? 'disapear' : ''" class="form-container">
        <img src="../../assets/img/logo.png" alt="">
        <h1>Se connecter</h1>
        <form @submit.prevent="submit">
            <div class="input-container">
                <input id="email" v-model="form.email" name="email" type="text" placeholder=" ">
                <label class="custom-label" for="email">E-mail *</label>
            </div>
            <div class="input-container">
                <img @click="show" :style="visible" class="show-hide" src="../../assets/img/show.svg" alt="">
                <input id="password" v-model="form.password" name="password" :type="passwordFieldType" placeholder=" ">
                <label class="custom-label" for="password">Mot de passe *</label>
            </div>
            <div class="error-container">
                <p v-if="errors !==''">{{errors}}</p>
            </div>
            <small>
                <router-link to="/auth/forget-password"> Mot de passe oublié ?</router-link>
            </small>
            <input class="submit dark" type="submit" value="Se connecter">
        </form>
        <p class="compte-question">
            Pas encore de compte? 
            <router-link to="/auth/register">S'inscrire</router-link>
        </p>
    </section>
</template>

<script>

import axios from 'axios'

export default {
    data() {
        return {
            form:{
                email: '',
                password: ''
            },
            errors: '',
            passwordFieldType: 'password',
            smooth: false
        }
    },
    methods: {
        submit(){
            if(this.form.email == '' || this.form.password == ''){
                this.errors = 'Veuillez remplir les champs manquants';
            }
            else{
                axios.post('http://localhost:8000/api/users/',this.form)
               
                this.smooth = true
                this.$emit('test')
                // this.$router.push({name: 'Dashboard'})
                
            }
        },
        show(){
            this.passwordFieldType = this.passwordFieldType === 'password'? 'text' : 'password' ;
        }

    },

    
    created() {
        axios.get('http://localhost:8000/api/users')
        .then((res)=>{
            console.log(res.data)
        })
        .catch(
            error => console.log(error)
        )
    },

    computed: {
        visible(){
            return this.passwordFieldType == 'password' ? 'opacity:.3;' : 'opacity:1;'
        },

        isFill(){
            if(this.form.email !== '' && this.form.password !== ''){
                return true;
            }
            else{
                return false;
            }
        }
    },

}
</script>

<style>

</style>