<template>
    <section class="pop-up-container">
        <div class="pop-up-text-container">
            <p class="title">Que recherchez-vous ?</p>
            <p class="paragraph activite">Pour les activités, nous proposons des packages “clés en main” complets ou des questions individuelles pour que vous puissiez préparer votre activité vous-même.</p>
            <div class="pref-container">
                <p class="pref">
                    Que préférez-vous ?
                </p>
                <div>
                    <input value="clef" id="clef" name="clef" v-model="inputValue" type="radio"/>
                    <label for="clef">Clés en main</label>
                </div>
                <div>
                    <input value="questions" id="question" name="question" v-model="inputValue" type="radio"/>
                    <label for="question">Questions individuelles</label>
                </div>
            </div>
            <router-link @click="redirect" class="submit light" to="#">Sélectionner</router-link>
            <button @click="close" class="submit white">Retour</button>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            inputValue: ''
        }
    },

    methods: {
        close(){
            this.$emit('closed')
        },
        redirect(){
            if(this.inputValue == "clef"){
                this.$emit('closed')
                this.$router.push('/admin/recherche/clef-en-main');
            }
            else if(this.inputValue == "questions"){
                this.$emit('closed')
                this.$router.push('/admin/recherche/questions-individuelles');
            }
           
        }
    },
}
</script>

<style>

</style>