<template>
    <section class="admin-wrapper">
        <h1>Classe</h1>
        <div v-if="classes.length == 0" class="classe-container">
            <h2>Aucune classe ajoutée</h2>
            <p>Ajoutez vos classes dès maintenant ! Votre planning s’actualisera et vous pourrez leur attribuer des ressources. </p>

            <router-link to="/admin/ajouter-classe" class="submit dark">Ajouter une classe</router-link>
        </div>
        <div v-else>
            <ul>
                {{classes.length}}
                <li v-for="classe in classes" :key="classe.id">
                    {{classe}}
                </li>
            </ul>
        </div>
    </section>
</template>

<script>

import axios from 'axios'
export default {

    data() {
        return {
            classes: []
        }
    },
    created() {
        axios.get('https://jsonplaceholder.typicode.com/users')
        // .then((res)=>{
        //     this.classes = res.data
        //     console.log(res.data)
        // })
        // .catch(
        //     error => console.log(error)
        // )
    },

    
}
</script>

<style>

</style>