<template>
    <div class="slider">
        <span @click="prev" id="left" class="btn"></span>
        <span @click="next" id="right" class="btn"></span>
        <div class="slide" v-for="i in [currentSlide]" :key="i">
            <img style="width: 100%; height: 250px" :src="currentImg" alt="">
        </div>
        
    </div>
</template>

<script>
export default {
   data () {
      return {
        currentSlide : 0,
        images:[
            '/img/template/template1.jpg',
            '/img/template/template2.jpg',
            '/img/template/template3.jpg',
            '/img/template/template4.jpg',
            '/img/template/template5.jpg',
            '/img/template/template6.jpg',
            '/img/template/template7.jpg',
         
        ],
       
      }
    },

    methods: {
      
        next(){
            this.currentSlide += 1;
        },
        prev(){
            this.currentSlide -= 1;
        }
    },
    computed: {
        currentImg(){
            return this.images[Math.abs(this.currentSlide) % this.images.length];
        }
    }
    
}
</script>