<template>
    <section class="admin-wrapper">
        <p class="path">Activités rituelles / <span class="blue"> Questions individuelles</span></p>
        <h1>Questions individuelles</h1>
        <p class="filter-paragraph">Filtrez votre recherche de questions par niveau, chapitres, etc.</p>
        <div style="height: 9rem">
            <button  v-if="questions.length > 0" @click="showPopUp" class="submit dark row">
                Importer 
                <span>&nbsp; ( {{questions.length}}
                    question<span v-if="questions.length > 1">s
                    </span> )
                </span>
            </button>
        </div>
        <div class="exemple-container">

            <div class="activite-container">
                <question-container @addQuestion="addQuestion(i)" v-for="i in 4" :key="i"/>
            </div>
        </div>
        <pop-up :questionLength="questions.length" @closed="showPopUp" v-if="isVisible"/>
    </section>
</template>

<script>
import QuestionContainer from './QuestionContainer.vue'
import PopUp from '../../PopUp/PopUpQuestion'


export default {

    components:{
        QuestionContainer,
        PopUp,
    },

    data() {
        return {
            questions: [],
            isVisible: false
        }
    },
    methods: {
        addQuestion(i){
            
            // this.questions.findIndex(x=>x==i) ? this.questions.push(i) : this.questions.splice(i, 1);
            const index = this.questions.indexOf(i);
            if (index > -1) {
                this.questions.splice(index, 1);
            }
            else{
                this.questions.push(i)
            }
            
        },
        showPopUp(){
            this.isVisible = !this.isVisible;
        }
    },
        
}
</script>

<style>

</style>