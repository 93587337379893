<template>
    <section class="home-container">
        <router-link class="submit dark" to="/auth">Login</router-link>
        <router-link class="submit dark" to="/admin">Admin</router-link>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>