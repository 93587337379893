import { createWebHistory, createRouter } from "vue-router";
import Home from '../components/Home';
import Login from '../components/Auth/Login';
import Register from '../components/Auth/Register';
import ForgetPassword from '../components/Auth/ForgetPassword';
import CompleteProfile from '../components/Auth/CompleteProfile';
import Dashboard from '../components/Admin/Dashboard';
import Classe from '../components/Admin/Classe';
import Ressource from '../components/Admin/Ressource';
import Agenda from '../components/Admin/Agenda';
import Parametre from '../components/Admin/Parametre';
import ActiviteRituelle from '../components/Admin/ActiviteRituelle';
import JeuSerieux from '../components/Admin/JeuSerieux';
import Evaluation from '../components/Admin/Search/Evaluation';
import Clef from '../components/Admin/Search/Clef';
import Question from '../components/Admin/Search/Question';
import Eleve from '../components/Admin/Search/Eleve';
import SearchClasse from '../components/Admin/Search/Classe';
import AddClasse from '../components/Admin/AddClasse';
import RessourceEnregistree from '../components/Admin/RessourceEnregistree';

const routes = [
    {
        path: '/',
        component: Home,
        name: 'Home'
    },
    {
        path: '/auth/',
        component: Login,
        name: 'Login'
    },
    {
        path: '/auth/register',
        component: Register,
        name: 'Register'
    },
    {
        path: '/auth/forget-password',
        component: ForgetPassword,
        name: 'ForgetPassword'
    },
    {
        path: '/auth/complete-profile',
        component: CompleteProfile,
        name: 'CompleteProfile'
    },
    {
        path: '/admin/dashboard',
        component: Dashboard,
        name: 'Dashboard'
    },
    {
        path: '/admin/classe',
        component: Classe,
        name: 'Classe'
    },
    {
        path: '/admin/ressource',
        component: Ressource,
        name: 'Ressource'
    },
    {
        path: '/admin/agenda',
        component: Agenda,
        name: 'Agenda'
    },
    {
        path: '/admin/parametre',
        component: Parametre,
        name: 'Parametre'
    },
    {
        path: '/admin/activite-rituelle',
        component: ActiviteRituelle,
        name: 'ActiviteRituelle'
    },
    {
        path: '/admin/jeu-serieux',
        component: JeuSerieux,
        name: 'JeuSerieux'
    },
    {
        path: '/admin/recherche/evaluation',
        component: Evaluation,
        name: 'Evaluation'
    },
    {
        path: '/admin/recherche/clef-en-main',
        component: Clef,
        name: 'Clef'
    },
    {
        path: '/admin/recherche/questions-individuelles',
        component: Question,
        name: 'Question'
    },
    {
        path: '/admin/recherche/recherche-eleve',
        component: Eleve,
        name: 'Eleve'
    },
    {
        path: '/admin/recherche/recherche-classe',
        component: SearchClasse,
        name: 'SearchClasse'
    },
    {
        path: '/admin/ajouter-classe',
        component: AddClasse,
        name: 'AddClasse'
    },
    {
        path: '/admin/ressource-enregistree',
        component: RessourceEnregistree,
        name: 'RecordedRessource'
    },
]


const router = createRouter({
    history: createWebHistory(),
    routes,
});
  
export default router;