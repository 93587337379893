<template>
    <section class="pop-up-container">
        <div class="pop-up-text-container">
            <p class="title">{{titre}}</p>
            <p class="paragraph">{{text}}</p>
            <router-link class="submit delete" :to="path">{{btn}}</router-link>
            <button @click="close" class="submit white">Annuler</button>
        </div>
    </section>
</template>

<script>
export default {
    
    props: {
        titre: String,
        text: String,
        btn: String,
        path: String,
        delete: Boolean,
    },

    methods: {
        close(){
            this.$emit('closed')
        }
    },
}
</script>

<style>

</style>