<template>
    <section class="admin-wrapper">
        <h1>Tableau de bord</h1>
        <div>
            <h2>Vos prochains cours</h2>
            <p>Vous n’avez pas de cours à venir. Créez une classe et complétez votre agenda.</p>
            <router-link to="/admin/ajouter-classe" class="submit dark">Ajouter une classe</router-link>
        </div>
        <div>
            <h2>Dernières ressources enregistrées</h2>
            <p>Vous n’avez pas encore enregistré de ressources. Recherchez une activité, un jeu ou une évaluation.</p>
            <router-link to="" class="submit light">Rechercher une ressource</router-link>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>