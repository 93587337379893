<template>
    <section class="pop-up-container">
        <div class="pop-up-text-container">
            <p class="title">Que recherchez-vous exactement ?</p>
            <p class="paragraph">Pour les jeux sérieux, nous proposons des ressources collectives pour la classe ou pour un seul élève.</p>
            <div class="pref-container">
                <p class="pref">
                    Que préférez-vous ?
                </p>
                <div>
                    <input value="classe" id="classe" name="classe" v-model="inputValue" type="radio"/>
                    <label for="classe">Pour la classe</label>
                </div>
                <div>
                    <input value="eleve" id="eleve" name="eleve" v-model="inputValue" type="radio"/>
                    <label for="eleve">Pour un élève</label>
                </div>
            </div>
            <router-link @click="redirect" class="submit light" to="#">Sélectionner</router-link>
            <button @click="close" class="submit white">Retour</button>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            inputValue: ''
        }
    },

    methods: {
        close(){
            this.$emit('closed')
        },
        redirect(){
            if(this.inputValue == "eleve"){
                this.$emit('closed')
                this.$router.push('/admin/recherche/recherche-eleve');
            }
            else if(this.inputValue == "classe"){
                this.$emit('closed')
                this.$router.push('/admin/recherche/recherche-classe');
            }
           
        }
    },
}
</script>

<style>

</style>