<template>
    <section class="form-container">
        <img src="../../assets/img/logo.png" alt="">
        <h1>S'inscrire</h1>
        <p class="register-p">Inscrivez-vous gratuitement pour découvrir toutes nos ressources.</p>
        <form @submit.prevent="submit">
            <div class="input-container">
                <input id="email" v-model="form.email" name="email" type="email" placeholder=" ">
                <label class="custom-label" for="email">E-mail *</label>
            </div>
            <div class="input-container">
                <img @click="show" :style="visible" class="show-hide" src="../../assets/img/show.svg" alt="">
                <input id="password" v-model="form.password" name="password" :type="passwordFieldType" placeholder=" ">
                <label class="custom-label" for="password">Mot de passe *</label>
            </div>
            <div class="error-container">
                <p v-if="errors !==''">{{errors}}</p>
            </div>
            <input class="submit dark" type="submit" value="S'inscrire">
        </form>
        <p class="compte-question">
            Déja un compte ?
            <router-link to="/auth">Se connecter</router-link>
        </p>
        <div v-if="showPopUp">
            <pop-up :titre="'Merci pour votre inscription !'" :text="'Finaliser votre inscription en complétant votre profil.'" :btn="'Finaliser mon inscription'" :path="'/auth/complete-profile'"/>
        </div>
    </section>
</template>

<script>

import PopUp from '../PopUp/PopUpAuth.vue'
export default {

    components: {
        PopUp,
    },
    data() {
        return {
            form:{
                email: '',
                password: ''
            },
            errors: '',
            passwordFieldType: 'password',
            isOk: false
        }
    },
    methods: {
        submit(){
            if(this.form.email == '' || this.form.password == ''){
                this.errors = 'Veuillez remplir les champs manquants';
            }
            else{
                this.isOk = true;
            }
        },
        show(){
            this.passwordFieldType = this.passwordFieldType === 'password'? 'text' : 'password' ;
        }

    },

    computed: {
        visible(){
            return this.passwordFieldType == 'password' ? 'opacity:.3;' : 'opacity:1;'
        },

        isFill(){
            if(this.form.email !== '' && this.form.password !== ''){
                return true;
            }
            else{
                return false;
            }
        },
        showPopUp(){
            if(this.isOk){
                console.log('aaaa')
                return true;
            }
            else{
                console.log('bbbb')
                return false;
            }
        }
    },
}
</script>

<style>

</style>