<template>
    <section class="admin-wrapper">
        <p class="path">Activités rituelles / <span class="blue"> Clés en main</span></p>
        <h1>Clés en main</h1>
        <p class="filter-paragraph">Filtrez votre recherche de questions par niveau, chapitres, etc.</p>
        <div class="filter-container">
            <input type="text" v-model="keyword" name="" id="" placeholder="Recherchez par mot clé ...">
            <div>
                <div>
                    <label for="">Niveaux</label>
                    <select name="" id="">

                        <option value="6eme">6eme</option>
                        <option value="5eme">5eme</option>
                        <option value="4eme">4eme</option>
                    </select>
                </div>  
            </div>
            <div class="multiselect">
                <div class="select-box" @click="showCheckboxes()">
                    <select>
                        <option>Choix des chapitres</option>
                    </select>
                    <div class="overSelect"></div>
                </div>
                <div id="checkboxes">
                    <p class="sequence">Fonctions</p>
                    <label for="one">
                        <input v-model="checkedChapters" value="les courbes" type="checkbox" id="one" />Définir une fonction
                    </label>
                    <label for="two">
                        <input v-model="checkedChapters" value="Les fonctions affines" type="checkbox" id="two" /> Variations de fonctions
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Fonctions affines
                    </label>
                    <p class="sequence">Nombres et calculs</p>
                    <label for="one">
                        <input v-model="checkedChapters" value="les courbes" type="checkbox" id="one" />Ensembles de nombres
                    </label>
                    <label for="two">
                        <input v-model="checkedChapters" value="Les fonctions affines" type="checkbox" id="two" />Intervalles
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Nombres entiers
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Calcul littéral
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Puissances et fractions 
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Racines
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Nombres entiers
                    </label>
                    <p class="sequence">Fonctions</p>
                    <label for="one">
                        <input v-model="checkedChapters" value="les courbes" type="checkbox" id="one" />Définir une fonction
                    </label>
                    <label for="two">
                        <input v-model="checkedChapters" value="Les fonctions affines" type="checkbox" id="two" /> Variations de fonctions
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Fonctions affines
                    </label>
                    <p class="sequence">Nombres et calculs</p>
                    <label for="one">
                        <input v-model="checkedChapters" value="les courbes" type="checkbox" id="one" />Ensembles de nombres
                    </label>
                    <label for="two">
                        <input v-model="checkedChapters" value="Les fonctions affines" type="checkbox" id="two" />Intervalles
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Nombres entiers
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Calcul littéral
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Puissances et fractions 
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Racines
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Nombres entiers
                    </label>
                    <p class="sequence">Fonctions</p>
                    <label for="one">
                        <input v-model="checkedChapters" value="les courbes" type="checkbox" id="one" />Définir une fonction
                    </label>
                    <label for="two">
                        <input v-model="checkedChapters" value="Les fonctions affines" type="checkbox" id="two" /> Variations de fonctions
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Fonctions affines
                    </label>
                    <p class="sequence">Nombres et calculs</p>
                    <label for="one">
                        <input v-model="checkedChapters" value="les courbes" type="checkbox" id="one" />Ensembles de nombres
                    </label>
                    <label for="two">
                        <input v-model="checkedChapters" value="Les fonctions affines" type="checkbox" id="two" />Intervalles
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Nombres entiers
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Calcul littéral
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Puissances et fractions 
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Racines
                    </label>
                    <label for="three">
                        <input v-model="checkedChapters" value="Un chapitre vraiment tres long" type="checkbox" id="three" />Nombres entiers
                    </label>
                </div> 
            </div>
        </div>

        <div class="exemple-container">

            <div class="display-chapter">
                <span @click="remove(checkedChapter)" v-for="(checkedChapter, index) in checkedChapters " :key="index">
                    {{checkedChapter}}
                    <span class="remove-plus"></span>
                </span>
            </div>
            <div class="activite-container">
                <div class="fake-container">
                    <div class="activite">
                        <div class="exo">
                            <p class="number">Question de cours sur les entiers naturels</p>
                            <p>Effectuer le calcul suivant:</p>
                            <p class="calcul">10^8*10^8</p>
                            <p class="consigne">On donnera la réponse sous la forme 10^n,1 ou 10, sachant que n est un entier positif</p>
                        </div>
                        <div class="exo">
                            <p class="number">Inégalité-intervalle</p>
                            <p>Effectuer le calcul suivant:</p>
                            <p class="calcul">10^8*10^8</p>
                            <p class="consigne">On donnera la réponse sous la forme 10^n,1 ou 10, sachant que n est un entier positif</p>
                        </div>
                        <div class="exo">
                            <p class="number">Question de cours sur les entiers naturels</p>
                            <p>Effectuer le calcul suivant:</p>
                            <p class="calcul">10^8*10^8</p>
                            <p class="consigne">On donnera la réponse sous la forme 10^n,1 ou 10, sachant que n est un entier positif</p>
                        </div>
                    </div>
                    <div class="add-container">
                        <p>Activité rituelle 1 </p>
                        <button class="submit add">Enregistrer</button>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
</template>

<script>

export default {

    data() {
        return {
            expanded: false,
            checkedChapters: []
        }
    },

    methods: {
        showCheckboxes() {
            var checkboxes = document.getElementById("checkboxes");
            if (!this.expanded) {
                checkboxes.style.display = "flex";
                this.expanded = true;
            } else {
                checkboxes.style.display = "none";
                this.expanded = false;
            }
        },
        remove(x){
            this.checkedChapters = this.checkedChapters.filter(checkedChapters => checkedChapters !== x)
        }
    },

    created() {

    },
        
}
</script>

<style>

</style>