<template>
    <section class="pop-up-container">
        <div class="pop-up-text-container">
            <p class="title">Choisir un template pour vos {{questionLength}} questions</p>
            
            <slider/>
            
            <router-link class="submit light" to="#">Attribuer</router-link>
            <button @click="close" class="submit white">Retour</button>
            
        </div>
        
    </section>
</template>

<script>

import Slider from '../Admin/Slider.vue'
export default {

    components: {
        Slider
    },

    props: {
        questionLength: Number
    },
    data() {
        return {
            inputValue: '',
            form:{
                ressource: 'Activité Rituelle 1'
            }
        }
    },

    methods: {
        close(){
            this.$emit('closed')
        },
        
    },
}
</script>

<style>

</style>