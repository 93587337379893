<template>
    <section class="pop-up-container">
        <div class="pop-up-text-container">
            <p class="title">Attribuer la ressource</p>
            <p class="paragraph">Attribuer la ressource à une ou plusieurs classe(s) et au(x) cours correspondant(s).</p>
            <form class="remove-margin" action="">
                <div class="input-container">
                    <input id="ressource" v-model="form.ressource" name="ressource" type="text" placeholder=" ">
                    <label class="custom-label" for="ressource">Nom de la ressource *</label>
                </div>

                <h2 class="remove-margin">Classe et date 1*</h2>
                <div class="input-container">
                    <select id="classe" v-model="form.classe" name="classe" type="text" placeholder=" ">
                        <option value="">6eme</option>
                        <option value="">5eme</option>
                        <option value="">4eme</option>
                    </select>
                    
                </div>
                <div class="input-container">
                    <input id="date" v-model="form.date" name="date" type="date" placeholder=" ">
                    <label class="custom-label" for="date">Date</label>
                </div>
                <div class="input-container">
                    <input id="heure" v-model="form.heure" name="heure" type="time" placeholder=" ">
                    <label class="custom-label" for="heure">Heure</label>
                </div>
           
                <router-link @click="redirect" class="submit light" to="#">Attribuer</router-link>
                <button @click="close" class="submit white">Retour</button>
            </form>
        </div>
        
    </section>
</template>

<script>
export default {
    data() {
        return {
            inputValue: '',
            form:{
                ressource: 'Activité Rituelle 1'
            }
        }
    },

    methods: {
        close(){
            this.$emit('closed')
        },
        redirect(){
            if(this.inputValue == "eleve"){
                this.$emit('closed')
                this.$router.push('/admin/recherche/recherche-eleve');
            }
            else if(this.inputValue == "classe"){
                this.$emit('closed')
                this.$router.push('/admin/recherche/recherche-classe');
            }
           
        }
    },
}
</script>

<style>

</style>