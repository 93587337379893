<template>
    <section class="form-container">
        <img src="../../assets/img/logo.png" alt="">
        <h1>Complétez votre profil</h1>
        <p class="register-p">Inscrivez-vous gratuitement pour découvrir toutes nos ressources.</p>
        <form @submit.prevent="submit">
            <div class="double-input-container">
                <div class="input-container">
                    <input id="prenom" v-model="form.prenom" name="prenom" type="text" placeholder=" ">
                    <label class="custom-label" for="prenom">prenom *</label>
                </div>
                <div class="input-container">
                    <input id="nom" v-model="form.nom" name="nom" type="text" placeholder=" ">
                    <label class="custom-label" for="nom">nom *</label>
                </div>
            </div>
            <div class="double-input-container">
                <div class="input-container">
                    <input id="etablissement" v-model="form.etablissement" name="etablissement" type="text" placeholder=" ">
                    <label class="custom-label" for="etablissement">Etablissement scolaire</label>
                </div>
                <div class="input-container">
                    <input id="diplome" v-model="form.diplome" name="diplome" type="text" placeholder=" " list="diplomes" multiple>
                    <label class="custom-label" for="diplome">Diplome</label>
                    <datalist id="diplomes">
                            
                        <option>CAPES</option>
                        <option>Agregation</option>
                        <option>Option 3</option>
                        <option>Option 4</option>
                    </datalist>
                </div>
            </div>
            <div class="input-container">
                <input id="enseignements" v-model="form.enseignements" name="enseignements" type="text" placeholder=" " list="enseignement">
                <label class="custom-label" for="enseignements">Enseignements</label>
                <datalist id="enseignement">
                        
                    <option>Math</option>
                    <option>Physique / Chimie</option>
                </datalist>
            </div>
            <div class="input-container">
                <input id="niveaux" v-model="form.niveaux" name="niveaux" type="text" placeholder=" " list="niveaux" multiple>
                <label class="custom-label" for="niveaux">Niveaux préférés</label>
                <datalist id="niveaux">
                        
                    <option>Seconde</option>
                    <option>Premiere</option>
                    <option>Terminale</option>
                </datalist>
            </div>
            <div class="error-container">
                <p v-if="errors !==''">{{errors}}</p>
            </div>
            <input :style="isFill === true ? 'background-color: #003594;' : 'background-color: #D9D9D9;'" class="submit dark" type="submit" value="Finaliser mon inscription">
        </form>
        <div v-if="showPopUp">
            <pop-up :titre="'Bienvenue ' + form.prenom + '!'" :text="'Vous allez désormais pouvoir gérer vos classes et ressources via votre espace.'" :btn="'Accéder à mon espace'" :path="'/admin/dashboard'"/>
        </div>
    </section>
</template>

<script>

import PopUp from '../PopUp/PopUpAuth.vue'

export default {
    components: {
        PopUp
    },
  
    data() {
        return {
            form:{
                prenom: '',
                nom: '',
                etablissement: '',
                diplomes: '',
                niveaux: '',
            },
            errors: '',
            passwordFieldType: 'password',
            isOk: false
        }
    },
    methods: {
        submit(){
            if(this.form.prenom == '' || this.form.nom == ''){
                this.errors = 'Veuillez remplir les champs manquants';
            }
            else{
                this.isOk = true;
            }
        },
        show(){
            this.passwordFieldType = this.passwordFieldType === 'password'? 'text' : 'password' ;
        }

    },

    computed: {
        visible(){
            return this.passwordFieldType == 'password' ? 'opacity:.3;' : 'opacity:1;'
        },

        isFill(){
            if(this.form.nom !== '' && this.form.prenom !== ''){
                return true;
            }
            else{
                return false;
            }
        },
        showPopUp(){
            if(this.isOk){
                return true;
            }
            else{
                return false;
            }
        }
    },
}
</script>

<style>

</style>