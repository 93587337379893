<template>
   <div :class="checkUrl">
		<menu-admin  v-if="checkUrlAdmin"/>
		<user-display  v-if="checkUrlAdmin"/>
		<need-help  v-if="checkUrlAdmin"/>
		<burger  v-if="checkUrlAdmin"/>
		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component class="main-component" :class="hidden ? 'hidden' : ''" @test="a" :is="Component"></component>
			</transition>
			<img v-if="checkUrlAuth" src="./assets/img/auth-bg.jpg" alt="">

		</router-view>
    </div>
</template>

<script>
import MenuAdmin from './components/Admin/MenuAdmin.vue'
import UserDisplay from './components/Admin/UserDisplay.vue'
import NeedHelp from './components/Admin/NeedHelp.vue'
import Burger from './components/Admin/Burger.vue'

export default {
	name: 'App',
	components: {
		MenuAdmin,
		UserDisplay,
		NeedHelp,
		Burger,
	},
	data() {
		return {
			hidden: false
		}
	},
	methods: {
		a(){
			this.hidden = true;
			setTimeout(()=>{
				this.hidden = false;
			},400)
		}
	},
	computed:{
		checkUrl(){
			if (this.$route.path.includes('/auth')) { 
                return 'auth-container'; 
            }
			if (this.$route.path.includes('/admin')) { 
                return 'admin-container'; 
            }
			else{
				return false
			}
		},
		checkUrlAuth(){
			if (this.$route.path.includes('/auth')) { 
                return true; 
            }
			else{
				return false
			}
		},
		checkUrlAdmin(){
			if (this.$route.path.includes('/admin')) { 
                return true; 
            }
			else{
				return false
			}
		}			
	},
}
</script>

<style lang="scss">

	@import './assets/scss/style.scss';

</style>
