<template>
    <section class="pop-up-container">
        <div class="pop-up-text-container">
            <img class="validate" src="../../assets/img/check.png" alt="">
            <p class="title">{{titre}}</p>
            <p class="paragraph">{{text}}</p>
            <router-link class="submit light" :to="path">{{btn}}</router-link>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        titre: String,
        text: String,
        btn: String,
        path: String,
    }
}
</script>

<style>

</style>