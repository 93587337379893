<template>
    <section class="admin-wrapper">
        <h1>Activités rituelles</h1>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>