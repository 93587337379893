<template>
    <section class="admin-wrapper ressources">
        <h1>Ressources</h1>
        <div class="filter">
            <label for="">Type  </label>
            <select>
                <option value="all">Tout</option>
                <option value="rituals">Activités rituelles</option>
                <option value="games">Jeux sérieux</option>
                <option value="evaluations">Evaluations</option>
            </select>
            <label for="">Niveaux  </label>
            <select>
                <option value="all">Tout</option>
                <option value="rituals">4 ème</option>
                <option value="games">5 ème</option>
                <option value="evaluations">6 ème</option>
            </select>
        </div>
        <h2>Ressources enregistrées</h2>
        <table>
            <tr>
                <th>Nom</th>
                <th>Type</th>
                <th>Niveau</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
            <tr v-for="data in tableData" :key="data">
                <td>{{data.name}}</td>
                <td><span :class="changeColor(data.type)">{{data.type}}</span></td> 
                <td>{{data.niveau}}</td>
                <td><img src="../../assets/img/eye.png" />  Voir</td>
                <td><button @click="showPopUp" class="submit dark">Attribuer</button></td>
                <td><img src="../../assets/img/three-dots.png" /></td>
            </tr>
        </table>
        <div class="filter">
            <label for="">Type</label>
            <select>
                <option value="all">Tout</option>
                <option value="rituals">Activités rituelles</option>
                <option value="games">Jeux sérieux</option>
                <option value="evaluations">Evaluations</option>
            </select>
            <label for="">Statut</label>
            <select>
                <option value="all">Tout</option>
                <option value="todo">&Agrave; faire</option>
                <option value="done">Fait</option>
            </select>
        </div>
        <h2>Ressources attribuées</h2>
        <table>
        <tr>
            <th>Nom</th>
            <th>Type</th>
            <th>Classe</th>
            <th>Date du concours</th>
            <th>Statut</th>
            <th></th>
        </tr>
        <tr v-for="data in tableData2" :key="data">
            <td>{{data.name}}</td>
            <td><span :class="changeColor(data.type)">{{data.type}}</span></td>
            <td>{{data.classe}}</td>
            <td>{{data.date}}</td>
            <td><input type="checkbox" v-model="data.statut" /></td>
            <td><img src="../../assets/img/three-dots.png" /></td>
        </tr>
    </table>
           
        <div v-if="this.delete">
            <pop-up />
        </div>
        
    </section>
</template>

<script>
import PopUp from '../PopUp/PopUpRessource.vue'

export default {
    components: {
        PopUp,
    },
    data(){
        return{
            delete:false,
             tableData: [{
                name: 'Activité Rituelle 1',
                type: 'Activités rituelles',
                niveau: 'Seconde'
            }, {
                name: 'Jeu Sérieux 1',
                type: 'Jeux sérieux',
                niveau: 'Seconde'
            }, {
                name: 'Evaluation 1',
                type: 'Evaluations',
                niveau: 'Seconde'
            }],
             tableData2: [{
                name: 'Activité Rituelle 1',
                type: 'Activités rituelles',
                classe: '2e A, 2e B',
                date:'30-10-2021',
                statut:true,
            }, {
                 name: 'Activité Rituelle 2',
                type: 'Activités rituelles',
                classe: '1ère B',
                date:'02-11-2021',
                statut:false,
            }, {
                name: 'Activité Rituelle 3',
                type: 'Activités rituelles',
                classe: 'Terminale S',
                date:'09-12-2021',
                statut:false,
            }],
        }
    },
    methods: {
        showPopUp(){
            this.delete = true
        },
        timeToClose(){
            this.delete = false
        },

        changeColor(e){
            if(e == "Activités rituelles"){
                return ('activites');
            }
            if(e == "Jeux sérieux"){
                return ('jeux');
            }
            else{

                 return('evaluations');   
            }

        }
    },


}

</script>

<style>

</style>