<template>
    <section class="admin-wrapper">
        <h1>Ajouter une classe </h1>

        <form @submit.prevent="submit">
            <div class="input-container">
                <input id="className" v-model="form.className" name="className" type="text" placeholder=" ">
                <label class="custom-label" for="className">Nom de la classe</label>
            </div>
            <div class="double-input-container">
                <select class="days" v-model="form.day" name="day" id="day">
                    <option value="">Choisir un jour</option>
                    <option value="Lundi">Lundi</option>
                    <option value="Mardi">Mardi</option>
                    <option value="Mercredi">Mercredi</option>
                    <option value="Jeudi">Jeudi</option>
                    <option value="Vendredi">Vendredi</option>
                </select>
                <div class="day">
                    <div  class="input-container">
                        <input id="datetimeStart" v-model="form.datetimeStart" name="datetimeStart" type="time" placeholder=" ">
                        <label class="custom-label day-label" for="datetimeStart">Heure de début</label>
                    </div>
                    <div class="input-container">
                        <input id="datetimeEnd" v-model="form.datetimeEnd" name="datetimeEnd" type="time" placeholder=" ">
                        <label class="custom-label day-label" for="datetimeEnd">Heure de fin</label>
                    </div>
                </div>
                <div class="horaire-list-container">
                    <div class="horaire-list" v-if="!isFirstTime">
                        <div class="list" v-for="(input, k) in form.inputs" :key="k">
                            <p>
                                {{input.day}}: {{input.datetimeStart}} à {{input.datetimeEnd}}
                                
                            </p>
                            <img @click="remove(k)" src="../../assets/img/close.svg" alt="">
                        </div>
                    </div>
                    <div>
                        <button :style="isFill2 === true ? 'background-color: #003594;' : 'background-color: #D9D9D9;'" type="button" @click="add" class="submit dark">Ajouter une horaire</button>
                    </div>
                </div>
                
            </div>
            
            <div class="error-container">
                <p v-if="errors !==''">{{errors}}</p>
            </div>
            <input :style="isFill === true ? 'background-color: #003594;' : 'background-color: #D9D9D9;'" class="submit dark" type="submit" value="Valider">

            
        </form>
        <pop-up v-if="showPopUp"/>

    </section>

</template>

<script>

import PopUp from '../PopUp/PopUpClasse.vue'

export default {

    components:{
        PopUp
    },

    data() {
        return {
            form:{
                className: '',
                day:'',
                datetimeStart: '',
                datetimeEnd: '',
                inputs: [{
                    day:'',
                    datetimeStart: '',
                    datetimeEnd: '',
                }],

            },
            errors: '',
            isOk: false,
            isFirstTime: true
        }
    },

    methods: {
        submit(){
            if(this.form.className == '' || this.form.dateTimeStart == '' || this.form.dateTimeEnd == ''){
                this.errors = 'Veuillez remplir les champs manquants';
            }
            else{
                this.isOk = true;
            }
        },
        add () {

            if(this.isFirstTime){
                this.isFirstTime= false;
                this.form.inputs[0].day = this.form.day;
                this.form.inputs[0].datetimeStart = this.form.datetimeStart;
                this.form.inputs[0].datetimeEnd = this.form.datetimeEnd;
            }
            else{
                this.form.inputs.push({
                    day: this.form.day,
                    datetimeStart: this.form.datetimeStart,
                    datetimeEnd: this.form.datetimeEnd,
                })
            }

            this.form.day = '';
            this.form.datetimeStart = '';
            this.form.datetimeEnd = '';
        },
        remove(e){
            this.form.inputs.splice(e,1)
        }
    },
    computed: {
        isFill(){
            if(this.form.className !== '' && !this.isFirstTime){
                return true;
            }
            else{
                return false;
            }
        },
        isFill2(){
            if(this.form.day !== '' && this.form.datetimeStart !== '' && this.form.datetimeEnd !== ''){
                return true;
            
            }
            else{
                return false;
            }
        },
        showPopUp(){
            if(this.isOk){
                return true
            }
            else{
                return false
            }
        }
    },
        
}
</script>

<style>

</style>