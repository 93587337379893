<template>
    <section class="pop-up-container">
        <div class="pop-up-text-container">
            <p class="title">Classe créée !</p>
            <p class="paragraph">La classe a bien été ajoutée et votre planning a été mis à jour.</p>
            
            <router-link class="submit light" to="/admin/classe">Retour vers vos classes</router-link>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            inputValue: ''
        }
    },

}
</script>

<style>

</style>