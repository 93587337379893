<template>
    <section class="admin-wrapper">
        <p class="path">Jeux sérieux / <span class="blue"> Pour la classe</span></p>
        <h1>Pour la classe</h1>
        <p class="filter-paragraph">Filtrez votre recherche de questions par niveau, chapitres, etc.</p>
        <div class="filter-container column">
            <input class="filter-input" type="text" v-model="keyword" name="" id="" placeholder="Recherchez par mot clé ...">
            <div>
                <div>
                    <label for="">Niveaux</label>
                    <select name="" id="">
                        <option value="Tout">Tout</option>
                        <option value="6eme">6eme</option>
                        <option value="5eme">5eme</option>
                        <option value="4eme">4eme</option>
                    </select>
                    <label for="">Type</label>
                    <select name="" id="">
                        <option value="Tout">Tout</option>
                        <option value="6eme">6eme</option>
                        <option value="5eme">5eme</option>
                        <option value="4eme">4eme</option>
                    </select>
                    <label for="">Thème</label>
                    <select name="" id="">
                        <option value="Tout">Tout</option>
                        <option value="6eme">6eme</option>
                        <option value="5eme">5eme</option>
                        <option value="4eme">4eme</option>
                    </select>
                </div>  
            </div>
        </div>

        <div class="exemple-container">
            <div class="activite-container">
                <div class="fake-container classe">
                    <div class="activite individuelle">
                        <img src="../../../assets/img/fake-img.jpg" alt="">
                    </div>
                    <div class="add-container">
                        <p>Activité rituelle 1 </p>
                        <button class="submit add">Enregistrer</button>
                    </div>    
                </div>
            </div>
        </div>
        
    </section>
</template>

<script>

export default {

    data() {
        return {
            
        }
    },
        
}
</script>

<style>

</style>