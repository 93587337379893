<template>
    <section class="admin-wrapper">
       <h1>Évaluations</h1>
        <p class="filter-paragraph">Filtrez votre recherche de questions par niveau, chapitres, etc.</p>
        

        <div class="exemple-container">
            <div class="activite-container">
                <div class="fake-container">
                    <div class="activite individuelle">
                        <div class="exo">
                            <p class="number">N°43211: 10^n * 10^m: réponse sous forme 10^k (puissances positives)</p>
                            <p>Effectuer le calcul suivant:</p>
                            <p class="calcul">10^8*10^8</p>
                            <p class="consigne">On donnera la réponse sous la forme 10^n,1 ou 10, sachant que n est un entier positif</p>
                        </div>
                    </div>
                    <div class="add-container">
                        <p>Activité rituelle 1 </p>
                        <button class="submit add">Enregistrer</button>
                    </div>    
                </div>
            </div>
        </div>
        
    </section>
</template>

<script>

export default {

    data() {
        return {
            
        }
    },
        
}
</script>

<style>

</style>