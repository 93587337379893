<template>
    <div class="user-container">
        <p>{User Name}</p>
        <div>{UN}</div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>