<template>
    <section class="admin-wrapper">
        <h1>Agenda</h1>
        <div class="classe-container agenda">
            <vue-cal 
                show-week-numbers
                hide-weekends
                
                
                lang="fr"
                locale="fr"
                :time-from="8 * 60" :time-to="18 * 60"
                >
            </vue-cal>
        </div>
        
    </section>
</template>

<script>

import axios from 'axios'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/i18n/fr.js'


export default {

    components: {
        VueCal
    },

    data() {
        return {
            
        }
    },
    created() {
        axios.get('https://jsonplaceholder.typicode.com/users')
        // .then((res)=>{
        //     this.classes = res.data
        //     console.log(res.data)
        // })
        // .catch(
        //     error => console.log(error)
        // )
    },

    
}
</script>

<style>

</style>