<template>
    <div :style="isVisible === false ? 'transform:translateY(10vh)' : ''" class="need-help-container">
        <div class="text-close-container">
            <p>Besoin d'aide ?</p>
            <div @click="close">
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: true
        }
    },
    methods: {
        close(){
            this.visible = false;
        }
    },

    computed:{
        isVisible(){
            return this.visible;
        }
    }
}
</script>

<style>

</style>