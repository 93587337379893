<template>
    <div :class="added ? 'active' : ''" class="fake-container">
        <div class="activite individuelle">
            <div class="exo">
                <p class="number">N°43211: 10^n * 10^m: réponse sous forme 10^k (puissances positives)</p>
                <p>Effectuer le calcul suivant:</p>
                <p class="calcul">10^8*10^8</p>
                <p class="consigne">On donnera la réponse sous la forme 10^n,1 ou 10, sachant que n est un entier positif</p>
            </div>
        </div>
        <div class="add-container">
            <p>Activité rituelle 1 </p>
            <button @click="add" class="submit add">
               <span v-if="added"> Enlever</span>
               <span v-else>Ajouter</span>
            </button>
        </div>    
    </div>
</template>

<script>
export default {
    data() {
        return {
            added: false
        }
    },

    methods: {
        add(){
            this.added = !this.added;


            this.$emit('addQuestion')
        }
    },
}
</script>

<style>

</style>