<template>
    <section class="admin-wrapper">
        <h1>Paramètres</h1>
        <div class="parameter-container">
            <ul>
                <span :style="'width:'+spanWidth + 'px;left:' + spanPosition +'px;'" class="underline"></span>
                <li id="profile" @click="changePage('profile')" :class="checkPage === 'profile' ? 'active' : '' ">Profil</li>
                <li id="abonnement" @click="changePage('abonnement')" :class="checkPage === 'abonnement' ? 'active' : '' ">Abonnement</li>
                <li id="notification" @click="changePage('notification')" :class="checkPage === 'notification' ? 'active' : '' ">Notifications</li>
            </ul>

            <div  v-if="page == 'profile'" class="parameter-wrapper">
                <div class="profile-remove">
                    <button @click="showPopUp" class="submit delete">Supprimer mon compte</button>
                </div>

                <div class="profile">
                    <div>
                        <p class="title">Nom</p>
                        <p>User Name</p>
                        <p class="title">Enseignements</p>
                        <p class="tile pink">Math</p>
                        <p class="tile pink">Physique/Chimie</p>
                    </div>
                    <div>
                        <p class="title">Établissement scolaire</p>
                        <p>Sorbonne Université</p>
                        <p class="title">Niveaux préférés</p>
                        
                        <p class="tile blue">Seconde</p>
                        <p class="tile blue">Première</p>
                        <p class="tile blue">Terminale</p>
                    </div>
                    <div>
                        <p class="title">Diplome</p>
                        <p>DUT</p>
                    </div>
                </div>
            </div>
            <div v-if="page == 'abonnement'" class="parameter-wrapper">
                <div class="abonnement">
                    <div>
                        <h2>Gérer votre abonnement</h2>
                        <p>Formule actuelle :  Essai gratuit</p>
                    </div>

                    <router-link class="submit dark" to="">Souscrire</router-link>
                </div>
            </div>

            <div v-if="page == 'notification'" class="parameter-wrapper">
                <div class="notification">
                    <div>
                        <div class="checkbox-container margin">
                            <input type="checkbox" v-model="notif" name="notif" id="notif">
                            <label for="notif">J’accepte de recevoir des notifications par mail.</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="deleteUser">
            <pop-up @closed="timeToClose" :titre="'Êtes-vous sûre de vouloir supprimer votre compte ?'" :text="'Toutes vos données seront supprimées et vous n’aurez plus accès à votre compte.'" :btn="'Supprimer mon compte'" :path="'/auth/complete-profile'"/>
        </div>
    </section>
</template>

<script>

import axios from 'axios'
import PopUp from '../PopUp/PopUpRemove.vue'
export default {
    components: {
        PopUp,
    },
    data() {
        return {
            page: 'profile',
            profileWidth: '',
            abonnementWidth: '',
            notificationWidth: '',
            profilePosition: '',
            abonnementPosition: '',
            notificationPosition: '',
            spanWidth : '',
            spanPosition: '',
            notif: '',
            delete: false,
            isCancel: false,
        }
    },
    created() {
        axios.get('https://jsonplaceholder.typicode.com/users')
        // .then((res)=>{
        //     this.classes = res.data
        //     console.log(res.data)
        // })
        // .catch(
        //     error => console.log(error)
        // )
    },

    methods: {
        changePage(e){
            this.page = e

            if(e == 'abonnement'){
                this.spanWidth = this.abonnementWidth
                this.spanPosition = this.abonnementPosition
            }

            else if(e == 'notification'){
                this.spanWidth = this.notificationWidth
                this.spanPosition = this.notificationPosition
            }
            else{
                this.spanWidth = this.profileWidth;
                this.spanPosition = this.profilePosition
            }
        },
        showPopUp(){
            this.delete = true
        },
        timeToClose(){
            this.delete = false
        }
    },

    computed: {
        checkPage(){
            return this.page
        },
        deleteUser(){
            if(this.delete){
                return true
            }
            else{
                return false
            }
        }
       
    },

    mounted() {
        const profile = document.getElementById('profile')
        const abonnement = document.getElementById('abonnement')
        const notification = document.getElementById('notification')

        this.profileWidth = profile.offsetWidth
        this.abonnementWidth = abonnement.offsetWidth
        this.notificationWidth = notification.offsetWidth

        this.profilePosition = profile.getBoundingClientRect().left - profile.getBoundingClientRect().left
        this.abonnementPosition = abonnement.getBoundingClientRect().left - profile.getBoundingClientRect().left
        this.notificationPosition = notification.getBoundingClientRect().left - profile.getBoundingClientRect().left

        this.spanWidth = this.profileWidth;
        
    },

    
}
</script>

<style>

</style>