<template>
    <section class="form-container">
        <img src="../../assets/img/logo.png" alt="">
        <h1>Mot de passe oublié</h1>
        <p class="register-p">Une demande de réinitialisation de mot de passe va vous être envoyée par mail.</p>
        <form @submit.prevent="submit">
            <div class="input-container">
                <input id="email" v-model="form.email" name="email" type="email" placeholder=" ">
                <label class="custom-label" for="email">E-mail *</label>
            </div>
            <div class="error-container">
                <p v-if="errors !==''">{{errors}}</p>
            </div>
            <input :style="isFill === true ? 'background-color: #003594;' : 'background-color: #D9D9D9;'" class="submit dark" type="submit" value="Réinitialiser mon mot de passe">
        </form>

    </section>
</template>

<script>
export default {
    data() {
        return {
            form:{
                email: '',
            },
            errors: '',
        }
    },
    methods: {
        submit(){
            if(this.form.email == ''){
                this.errors = 'Veuillez remplir le champ manquant';
            }
        }
    },

    computed: {
        isFill(){
            if(this.form.email !== '' && this.form.password !== ''){
                return true;
            }
            else{
                return false;
            }
        }
    },


}
</script>

<style>

</style>